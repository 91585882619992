import React from "react"
import Underline from "../../library/underline/underline"
import sectionHeader from "./section-header.module.scss"

function SectionHeader(props) {
  const { header, subHeader, underlineColor } = props
  return (
    <div className={sectionHeader.container}>
      <h2 className={sectionHeader.service_title}> {header}</h2>
      <Underline color={underlineColor || "#333"} />
      <h3 className={sectionHeader.service_subtitle}>{subHeader}</h3>
    </div>
  )
}
export default SectionHeader

import React from "react"

import sectionStyles from "./section.module.scss"

function Section(props) {
  const { bgImage, bgColor } = props

  const sectionStyle = {
    backgroundImage: bgImage ? `url(${bgImage})` : null,
    backgroundSize: "cover",
    maxWidth: (bgImage || bgColor) && "none",

    backgroundColor: bgColor ? bgColor : bgImage && `rgba(0,0,0,0.5)`,
    backgroundBlendMode: bgImage && "multiply",
  }
  const { children } = props
  return (
    <div style={sectionStyle} className={sectionStyles.container}>
    
      {children}
    </div>
  )
}

export default Section

import React, { useState } from "react"
import Button from "../../library/button/button"
import emailjs from "emailjs-com"
import formStyles from "./form.module.scss"

function Form() {
  const [date, setDate] = useState("")
  const [hostName, setHostName] = useState("")
  const [typeOfEvent, setTypeOfEvent] = useState("")
  const [numberOfInvitees, setNumberOfInvitees] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const [status, setStatus] = useState("Submit")

  function handleSubmit(e) {
    e.preventDefault()
    let templateParams = {
      to_name: email,
      subject: "Client requesting your services",
      from_name: hostName,
      date: date,
      type_of_event: typeOfEvent,
      number_of_invitees: numberOfInvitees,
      message_html: message,
    }

    const serviceID = "default_service"
    const templateID = "template_2zfvn7n"

    emailjs
      .send(serviceID, templateID, templateParams, "user_V9YKYT1GZernoWBxipc7k")
      .then(
        response => {
          console.log("SUCCESS!", response.status, response.text)

          setStatus("Submitted")
        },
        err => {
          console.log("FAILED...", err)
        }
      )
      .then(resetForm())
  }
  function resetForm() {
    setNumberOfInvitees("")
    setStatus("Submit")
    setTypeOfEvent("")
    setHostName("")
    setDate("")
    setMessage("")
    setEmail("")
  }

  return (
    <div className={formStyles.container}>
      <div className={formStyles.wrapper}>
        <div className={formStyles.image}></div>
        <div className={formStyles.form_wrapper}>
          <form onSubmit={handleSubmit}>
            <div className={formStyles.form_row}>
              <input
                type="text"
                placeholder="Host Name"
                name="hostName"
                required
                id="hostName"
                value={hostName}
                onChange={e => setHostName(e.target.value)}
              />
              <input
                type="text"
                placeholder="Email Address"
                name="email"
                id="email"
                required
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </div>
            <div className={formStyles.form_row}>
              <input
                type="text"
                name="typeOfEvent"
                id="typeOfEvent"
                placeholder="Type of Event"
                value={typeOfEvent}
                onChange={e => setTypeOfEvent(e.target.value)}
              />
              <input
                type="number"
                name="numberOfInvitees"
                id="numberOfInvitees"
                placeholder="Number Of Invitees"
                value={numberOfInvitees}
                onChange={e => setNumberOfInvitees(e.target.value)}
              />
            </div>
            <div className={formStyles.form_row}>
              <input
                type="date"
                placeholder="Event Date"
                name="date"
                id="date"
                required
                value={date}
                onChange={e => setDate(e.target.value)}
              />
            </div>
            <div className={formStyles.form_row}>
              <textarea
                type="text"
                name="message"
                id="message"
                className="message"
                placeholder="Message"
                value={message}
                onChange={e => setMessage(e.target.value)}
              />
            </div>
            <Button
              className={formStyles.button}
              label={status}
              onClick={handleSubmit}
            />
          </form>
        </div>
      </div>
    </div>
  )
}

export default Form

import React from "react"
import buttonStyles from "./button.module.scss"

function Button(props) {
  const { label, onClick, isPrimary } = props
  return (
    <button
      className={
        isPrimary ? buttonStyles.primary_button : buttonStyles.container
      }
      onClick={onClick}
    >
      {label}
    </button>
  )
}

export default Button

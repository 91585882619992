import React from "react"
import Button from "../../library/button/button"
import heroStyles from "./hero.module.scss"

function Hero(props) {
  const { image, children, homePage, title, bgPosition } = props

  const bgStyle = {
    backgroundImage: `url(${image})`,
    backgroundPositionY: bgPosition
  }

  return (
    <div
      style={bgStyle}
      className={
        homePage ? heroStyles.container : heroStyles.non_home_container
      }
    >
      <div className={heroStyles.content}>
        {homePage && (
          <div className={heroStyles.homepage_content}>
            <h1 className={heroStyles.homepage_title}>Memories Begin Here!</h1>

            <h3 className={heroStyles.homepage_subheader}>
              Let us make your event the most memorable
            </h3>

            <Button
              onClick={() => (window.location.href = "/contact-us")}
              isPrimary
              label="Get Started"
            />
          </div>
        )}
        {children}
        {title && <h1 className={heroStyles.title}>{title}</h1>}
      </div>
    </div>
  )
}

export default Hero

import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero/hero"
import Form from "../components/form/form"
import Section from "../library/section/section"
import SectionHeader from "../components/section-header/section-header"
import ContactBanner from "../components/contact-banner/contact-banner"

function ContactUs() {
  return (
    <Layout>
      <SEO title="Home" />
      <Hero
        title="Contact Us"
        image="https://images.unsplash.com/photo-1543647380-25d37ad9a4ea?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1951&q=80"
      ></Hero>

      <Section>
        <SectionHeader header="Say Hello!" subHeader="" />
        <Form />
      </Section>
      <ContactBanner background="#f1f1f1" />
    </Layout>
  )
}

export default ContactUs

import React from "react"
import underLineStyles from "./underline.module.scss"

function Underline(props) {
  const { color } = props
  const style = { backgroundColor: `${color ? color: '#333'}` }
  return (
    <div className={underLineStyles.container}>
      <div style={style} className={underLineStyles.line}></div>
    </div>
  )
}

export default Underline
